import { render, staticRenderFns } from "./WOrders.vue?vue&type=template&id=f0246eaa&scoped=true&"
import script from "./WOrders.vue?vue&type=script&lang=js&"
export * from "./WOrders.vue?vue&type=script&lang=js&"
import style0 from "./WOrders.vue?vue&type=style&index=0&id=f0246eaa&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0246eaa",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WBranch from '@/components/WBranch.vue'
import WLoading from '@/components/WLoading.vue'
import WOrderPaymentDetails from '@/components/WOrderPaymentDetails.vue'
autoInstallComponents(component, {WBranch,WLoading,WOrderPaymentDetails})
