<template>
    <div v-if="item" class="text-xs italic">
        Sucursal: {{item.name}}
    </div>
</template>

<script>
export default {
  name: "Branch",
  props: {
      branchId: {
          type: String,
          default: null
      },
  },
  computed: {
    id() {
      return this.branchId;
    },
    // Returns a new Todo if the route `id` is 'new', or returns an existing Todo.
    item() {
      const { Branchs } = this.$FeathersVuex.api;
      return Branchs.getFromStore(this.id);
    },
  },
  watch: {
    id: {
      handler(val) {
        // Early return if the route `:id` is 'new'
        if (val === "new") {
          return;
        }
        const { Branchs } = this.$FeathersVuex.api;
        const existingRecord = Branchs.getFromStore(val);

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Branchs.get(val);
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true,
    },
  },
  data() {
    return {
      saved: false,
      saving: false,
      discountTypes: [
        {key:"amount", label: "Monto Fijo"},
        {key:"percentage", label: "Porcentaje"},
      ],
      productTypes: [
        {key:"books", label: "Libro"},
        {key:"ebook", label: "Libro Digital"},
        {key:"magazine", label: "Revista"},
        {key:"streamings", label: "Evento Streaming"},
      ],
      
      deliveryTimes: [
        {key:0, label: "En el día"},
        {key:"3-days", label: "1-3 Días"},
        {key:"1-week", label: "1 Semana"},
        {key:"2-week", label: "15 Días"},
        {key:"4-week", label: "15-30 Días"},
      ],

    }
  },
  methods: {
    async doSave(save){
      this.saved = false
      this.saving = true;
      let saved = await save();
      this.saving = false;
      this.saved = true;
      const self = this;
      setTimeout(()=>{
        self.saved = false;
      }, 3000)
      this.handleSaveReponse(saved);
    },
    handleSaveReponse(savedBranchs) {
      // Redirect to the newly-saved item
      console.log("handleSaveReponse", savedBranchs);
      if (this.id === "new") {
        this.$router.push({ params: { id: savedBranchs._id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>