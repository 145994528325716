<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto md:overflow-hidden sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 overflow-hidden align-middle sm:px-6 lg:px-8">
        <div class="flex flex-col items-center justify-center h-48" v-if="items.length === 0">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-12 h-12 text-base-300"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="my-2 font-normal text-gray-700">No hay pedidos</div>
        </div>
        <div v-else class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
          <table class="min-w-full overflow-hidden divide-y divide-gray-200 rounded-lg">
            <thead class="hidden md:table-header-group print:table-header-group">
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Nombre
                </th>
                <th
                  v-if="hide.indexOf('event') == -1"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Pedido
                </th>
                <th
                  v-if="hide.indexOf('event') == -1"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden bg-gray-50"
                >
                  Pago
                </th>
                <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                  v-if="isAdmin"
                >
                  Acciones
                </th>
                <th
                  class="py-3 text-xs font-medium leading-4 tracking-wider text-right text-gray-500 uppercase print:hidden sm:hidden bg-gray-50"
                >
                  Procesado
                </th>
              </tr>
            </thead>
            <tbody class="flex-1 bg-white divide-y divide-gray-300 sm:flex-none print:flex-none">
              <tr
                class="flex flex-nowrap sm:table-row print:table-row sm:mb-0"
                v-for="order in items"
                :key="order._id"
              >
                <td
                  class="flex w-1/2 md:w-auto md:px-6 md:py-3 px-4 py-2 print:px-6 print:py-3 overflow-hidden whitespace-no-wrap"
                >
                  <div class="">
                    <div class="md:w-auto print:w-auto">
                      <span class="text-xs italic text-gray-500">#{{ order.orderId }}</span>
                      <div
                        @click="
                          buscar([order.shipping.name, ' ', order.shipping.lastName].join(''))
                        "
                        v-tooltip="'Buscar por nombre'"
                        v-if="order.shipping"
                        class="hover:text-blue-700 hover:zoom pointer text-xs leading-5 text-gray-900 md:text-sm"
                      >
                        {{ order.shipping.name }} {{ order.shipping.lastName }}
                      </div>
                      <div
                        v-else-if="order.customer"
                        class="text-xs leading-5 text-gray-900 md:text-sm"
                      >
                        {{ order.customer.name }} {{ order.customer.lastName }}
                      </div>
                      <div
                        v-else
                        class="text-xs italic font-medium leading-5 text-gray-400 md:text-sm"
                      >
                        Sin Nombre
                      </div>
                      <div
                        @click="buscar(order.shipping.email)"
                        v-tooltip="'Buscar por email'"
                        v-if="order.shipping"
                        class="text-xs leading-5 text-gray-500 md:text-sm hover:text-blue-700 hover:zoom pointer"
                      >
                        {{ order.shipping.email }}
                      </div>
                      <div
                        v-else-if="order.customer"
                        class="text-xs leading-5 text-gray-500 md:text-sm"
                      >
                        {{ order.customer.email }}
                      </div>
                      <div
                        v-if="order.customer && order.customer.phone"
                        class="flex items-center text-xs leading-5 text-gray-500 md:text-sm"
                      >
                        <div>
                          {{ order.customer.phone.formatInternational }}
                        </div>
                        <a
                          v-bind:href="order.customer.phone.formatInternational | whatsapp"
                          target="_blank"
                          ><svg
                            style="color: #25d366"
                            class="w-3 h-3 ml-1 cursor-pointer"
                            fill="currentColor"
                            role="img"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>WhatsApp icon</title>
                            <path
                              d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"
                            /></svg
                        ></a>
                      </div>
                    </div>
                    <div class="text-xs italic text-gray-500">
                      hace
                      {{ order.foodUpdated || order.createdAt | moment("from", true) }}
                    </div>
                  </div>
                </td>
                <td
                  class="whitespace-no-wrap w-1/2 md:w-auto flex md:table-cell items-center justify-center"
                >
                  <div
                    class="text-xs hidden md:block"
                    v-for="(it, itIndex) in order.cart"
                    :key="itIndex"
                  >
                    <div v-if="!it.variant">
                      {{ it.qty }} x {{ it.sku || it.name }}

                      (${{ it.price.ars * it.qty || 0 }})
                    </div>
                    <div v-else>
                      <div v-for="(variant, vIndex) in it.variant" :key="vIndex" class="max-w-sm">
                        <div>{{ it.qty || 1 }} x {{ it.name }} ({{ variant.name }})</div>
                      </div>
                    </div>
                  </div>
                  <div :key="i" v-for="(it, i) in procesarApuestas(order.cart)" class="text-xs">
                    1 x {{ it }} <i class="text-xs text-gray-500">- apuesta</i>
                  </div>
                  <w-branch v-if="order.branchId" :branchId="order.branchId"></w-branch>
                  <w-order-payment-details class="text-sm md:hidden" v-if="order" :order="order" />
                </td>
                <td
                  class="hidden px-6 py-4 text-sm whitespace-no-wrap md:table-cell flex"
                  style="vertical-align: top"
                >
                  <div class="text-xs w-24">
                    <w-order-payment-details v-if="order" :order="order" />
                  </div>
                </td>
                <td
                  v-if="isAdmin"
                  style="vertical-align: top"
                  class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap md:w-32"
                >
                  <div class="relative flex justify-end print:hidden">
                    <a
                      v-if="order.status != 'paid'"
                      @click="makePayment(order._id)"
                      v-tooltip="'Marcar como pago'"
                      class="flex items-center hidden text-xs font-semibold text-red-700 transition duration-150 ease-in-out mr-2 pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5 mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                        />
                      </svg>
                    </a>

                    <a
                      v-tooltip="'Ver transacción en Mercado Pago'"
                      target="_blank"
                      v-if="
                        order.merchantOrder &&
                        order.merchantOrder.payments &&
                        order.method == 'mercadopago'
                      "
                      :href="
                        'https://www.mercadopago.com.ar/activities/1?q=' +
                        order.merchantOrder.payments[0].id
                      "
                      class="flex items-center text-xs font-semibold text-green-700 transition duration-150 ease-in-out"
                    >
                      <svg
                        class="w-5 h-5 mr-1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                        />
                      </svg>
                    </a>
                    <a
                      @click="sendEmail(order)"
                      v-if="
                        order.customer &&
                        (order.customer.email === 'walter.gammarota@gmail.com' ||
                          order.customer.email === 'casciari@gmail.com')
                      "
                      v-tooltip="'Enviar recibo por correo'"
                      class="flex items-center text-xs font-semibold text-green-700 transition duration-150 ease-in-out cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5 mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                        />
                      </svg>
                    </a>
                    <!-- Icono para marcar como paga -->
                    <a
                      v-tooltip="'Marcar como pago'"
                      @click="makePayment(order._id)"
                      class="flex items-center text-xs font-semibold text-red-700 transition duration-150 ease-in-out"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                        />
                      </svg>
                    </a>

                    <a
                      v-tooltip="'Ver página gracias por tu compra'"
                      target="_blank"
                      :href="
                        'https://tienda.orsai.org/compra/gracias/' +
                        ObjectID64(order._id) +
                        '?admin=true'
                      "
                      class="flex items-center text-xs font-semibold text-green-700 transition duration-150 ease-in-out"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5 mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                        />
                      </svg>
                    </a>
                    <router-link
                      v-tooltip="'Ver envíos asociados'"
                      v-if="order.cart && order.cart.some((it) => !it.digital)"
                      :to="{ path: 'shipments', query: { orderId: order._id } }"
                      class="flex items-center text-xs font-semibold transition duration-150 ease-in-out text-marine-700"
                    >
                      <svg
                        class="w-5 h-5 mr-1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          fill="#fff"
                          d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                        />
                      </svg>
                    </router-link>
                    <button
                      v-tooltip="'Archivar pedido'"
                      v-if="!order.archive"
                      @click="archive(order, true)"
                      class="flex items-center text-xs font-semibold text-orange-700 transition duration-150 ease-in-out"
                    >
                      <svg
                        v-if="busy == order._id"
                        class="w-5 h-5 mr-1 animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5 mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                        />
                      </svg>
                      <!-- <span>Archivar</span> -->
                    </button>
                    <button
                      v-if="order.archive"
                      @click="archive(order, false)"
                      class="flex items-center text-xs font-semibold text-white transition duration-150 ease-in-out bg-red-500 rounded hover:bg-red-700"
                    >
                      <svg
                        v-if="busy == order._id"
                        class="w-3 h-3 mr-1 animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span>Deshacer</span>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="!loading"
            @click="nextPage()"
            class="items-center p-3 text-sm font-medium text-center text-indigo-300 cursor-pointer glex hover:text-indigo-500"
          >
            Cargar más
          </div>
          <div
            v-else
            class="items-center p-3 text-sm font-medium text-center text-indigo-300 cursor-pointer glex hover:text-indigo-500"
          >
            <w-loading></w-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ObjectID64 } from "objectid64";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Array,
      default: () => [],
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    eventPrice: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showImage: null,
      upload: null,
      limit: 20,
      busy: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.isAdmin;
    },
  },
  methods: {
    ...mapActions("mails", { createMail: "create" }),
    ...mapActions("orders", { patchOrder: "patch" }),
    procesarApuestas(cart) {
      const apuesta = cart.find((it) => it.apuesta);
      if (!apuesta) return [];
      // Apuesta es un objeto con las apuestas, y debo devoler un array con los 3 primeros
      const apuestasArray = Object.values(apuesta.apuesta);
      return apuestasArray.slice(0, 3);
    },
    makePayment(orderId) {
      this.patchOrder([
        orderId,
        {
          status: "paid",
          procesadoDesdeAdmin: true,
        },
      ]);

      this.$toast.success("Pedido marcado como pago");
      this.$emit("reload");
    },

    buscar(query) {
      this.$router.push({ path: "orders", query: { search: query } });
    },
    sendEmail(order) {
      console.log("send email", order);
      this.createMail({
        key: "confirmation",
        orderId: order._id,
      });
    },
    ObjectID64(val) {
      const encoder = new ObjectID64();
      return encoder.encode(val);
    },
    async archive(order, status) {
      console.log("archive");
      this.busy = order._id;
      await this.$store.dispatch("food-orders/patch", [order._id, { archive: status }, {}]);
      // this.$emit("reload");
      this.busy = false;
    },
    nextPage() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="css" scoped>
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.pointer {
  cursor: pointer;
}
</style>
